import React from "react"
import PropTypes from "prop-types"

export default function FooterCircles({
  className,
  width,
  height,
  color,
  accentColor,
  mutedColor,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "150"}
      height={height || "138"}
      fill="none"
      viewBox="0 0 150 138"
      className={className}
    >
      <path
        stroke={mutedColor || "#B0B0B0"}
        d="M32.5 101.25L86.25 47.5m-50 64.375L96.875 51.25m-62.5 55.625l57.5-57.5M38.75 116.25l62.5-62.5M41.875 120.625L105 57.5m-60 66.875l63.75-63.75m-60 66.875l63.125-63.125M53.125 130L115 68.125M57.5 132.5l60-60m-55 61.875L119.375 77.5m-51.25 58.75l53.125-53.125M75 136.25l46.875-46.875M82.5 136.25l38.75-38.75m-30 36.875l28.125-28.125M33.75 79.375L64.375 48.75m-32.5 39.375l41.25-41.25M31.875 95L80 46.875"
      ></path>
      <circle
        cx="45.625"
        cy="45.625"
        r="45.125"
        stroke={color || "#000"}
      ></circle>
      <circle
        cx="104.375"
        cy="45.625"
        r="45.125"
        fill={accentColor || "red"}
        fillOpacity="0.1"
        stroke={accentColor || "red"}
      ></circle>
      <circle
        cx="76.875"
        cy="91.875"
        r="45.125"
        stroke={mutedColor || "#B0B0B0"}
      ></circle>
    </svg>
  )
}

FooterCircles.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  accentColor: PropTypes.string,
  mutedColor: PropTypes.string,
}
