import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import LogoIcon from "./svg/logoIcon"
import styles from "./header.module.css"
import NavigationMenuIcon from "./svg/navigationMenuIcon"

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.height = "100vh"
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.height = ""
      document.body.style.overflow = ""
    }
  }, [showMobileMenu])
  return (
    <header className={styles.header}>
      <div className={styles.leftSide}>
        <Link to="/">
          <LogoIcon
            className={styles.logo}
            mainClassName={styles.logoMain}
            accentClassName={styles.logoAccent}
            backgroundClassName={styles.logoBackground}
          />
        </Link>
      </div>
      <div className={styles.rightSide}>
        <NavigationMenuIcon
          className={styles.navigationMobileButton}
          isOpen={showMobileMenu}
          onClick={() => {
            setShowMobileMenu(!showMobileMenu)
          }}
        />
        <ul className={styles.navigation}>
          <li>
            <Link
              to="/ios/"
              activeClassName={styles.active}
              partiallyActive={true}
            >
              iOS<span className={styles.label}>beta</span>
            </Link>
          </li>
          <li>
            <a href="https://app.graphica.ai/" target="_blank" rel="noopener noreferrer">
              web<span className={styles.label}>alpha</span>
            </a>
          </li>
          <li>
            <a href="https://studio.graphica.ai/" target="_blank" rel="noopener noreferrer">
              studio
            </a>
          </li>
          <li>
            <Link
              to="/stories/"
              activeClassName={styles.active}
              partiallyActive={true}
            >
              stories
            </Link>
          </li>
        </ul>
        {showMobileMenu && (
          <div className={styles.mobileNavigationMenu}>
            <h3>Applications</h3>
            <ul>
              <li>
                <Link to="/ios/">iOS<span className={styles.label}>beta</span></Link>
              </li>
              <li>
                <a href="https://app.graphica.ai/" target="_blank" rel="noopener noreferrer">
                  web<span className={styles.label}>alpha</span>
                </a>
              </li>
            </ul>
            <h3>About us</h3>
            <ul>
              <li>
                <a href="https://studio.graphica.ai/" target="_blank" rel="noopener noreferrer">
                  studio
                </a>
              </li>
              <li>
                <Link to="/stories/">stories</Link>
              </li>
              <li>
                <Link to="/">community</Link>
              </li>
            </ul>
            <h3>More</h3>
            <ul>
              <li>
                <a href="mailto:hello@graphica.ai" className={styles.accent}>
                  hello@graphica.ai
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/v2rge2D"
                  target="_blank"
                  rel="noreferrer"
                >
                  helpdesk
                </a>
              </li>
              <li>
                <Link to="/privacy-policy/">privacy policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use/">terms of use</Link>
              </li>
            </ul>
            <p className={styles.copyrightTextHolder}>
              <span className={styles.copyright}>
                <span className={styles.copyrightSymbol}>©</span> graphica.ai,
                all rights reserved
              </span>
            </p>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
