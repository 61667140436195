import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, mainClassName, pageWrapperClassName }) => {
  return (
    <div className={pageWrapperClassName}>
      <Header />
      <div className={mainClassName}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
