import { Link } from "gatsby"
import React from "react"
import styles from "./footer.module.css"
import FooterCircles from "./svg/footerCircles"

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftSide}>
          <h3>Our Manifest</h3>
          <p>
            We’ve built a unique culture inside our company
            <br />
            that helps us to keep focus and balance on the overlap
            <br />
            of design, technology, and business innovation
          </p>
          <FooterCircles className={styles.footerCircles} />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.column}>
            <h3>Applications</h3>
            <ul>
              <li>
                <Link to="/ios/">iOS<span className={styles.label}>beta</span></Link>
              </li>
              <li>
                <a
                  href="https://app.graphica.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  web<span className={styles.label}>alpha</span>
                </a>
              </li>
            </ul>
            <div className={styles.subColumn}>
              <h3>About us</h3>
              <ul>
              <li>
                <a
                  href="https://studio.graphica.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  studio
                </a>
              </li>
                <li>
                  <Link to="/stories/">stories</Link>
                </li>
                <li>
                <a
                  href="https://www.instagram.com/graphica.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ig community
                </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${styles.column} ${styles.hideMd}`}>
            <h3>About us</h3>
            <ul>
              <li>
                <a
                  href="https://studio.graphica.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  studio
                </a>
              </li>
              <li>
                <Link to="/stories/">stories</Link>
              </li>
              <li>
              <a
                  href="https://www.instagram.com/graphica.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ig community
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <h3>More</h3>
            <ul>
              <li>
                <a href="mailto:hello@graphica.ai" className={styles.accent}>
                  hello@graphica.ai
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/v2rge2D"
                  target="_blank"
                  rel="noreferrer"
                >
                  helpdesk
                </a>
              </li>
              <li>
                <Link to="/privacy-policy/">privacy policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use/">terms of use</Link>
              </li>
            </ul>
            <p className={styles.copyrightTextHolder}>
              <span className={styles.copyright}>
                <span className={styles.copyrightSymbol}>©</span> graphica.ai,
                all rights reserved
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
